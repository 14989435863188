
import React, { useCallback, useEffect } from "react";

import { Query } from "react-apollo";

import { useDropzone } from "react-dropzone";

import gql from "graphql-tag";

import { Layout, Icon, Badge, Avatar, Spin, Form, Input, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select } from 'antd';

import { useMutation } from "@apollo/react-hooks";

import { Alert } from '../../Layout/Files';

import { URI } from '../../../graphql/Client';

import { ens } from '../../Layout/TemplateHeader';

import { strings } from '../../Layout/Strings';

const { TextArea } = Input;

const { Option } = Select;

const UPDATE_ADMIN = gql`
      mutation UPDATEADMIN($_id:ID,$user_name: String,$sure_name: String, $photo: String,$file:[Upload], $email: String,$user_type: String,$password:String,$lang:String) {   
                  updateAdmin(_id:$_id,user_name: $user_name,sure_name: $sure_name, photo: $photo,file:$file,email: $email, user_type: $user_type,password:$password,lang:$lang)
      {
        msg
        status         
      }
}`

export const Update = (props) => {

      console.log(props);

      const [user_name, setuser_name] = React.useState('');

      const [sure_name, setsure_name] = React.useState('');

      const [email, setemail] = React.useState('');

      const [user_type, setuser_type] = React.useState('');

      const [password, setpassword] = React.useState('');

      const [file, setFile] = React.useState('');

      const [imageUrl, setimageUrl] = React.useState('');

      const [uploadFile] = useMutation(UPDATE_ADMIN, {});

      const [spin, setspin] = React.useState(0);


      useEffect(() => {

            console.log(props);

            setuser_name(props.data.user_name);

            setsure_name(props.data.sure_name);

            setemail(props.data.email);

            setuser_type(props.data.user_type);

            setimageUrl(props.data.url);

            setpassword(props.data.password);

      }, [props.data]);

      const getBase64 = (file) => {

            var reader = new FileReader();

            reader.readAsDataURL(file[0]);

            reader.onload = () => {

                  console.log(reader.result);

                  setimageUrl(reader.result);

            };

            reader.onerror = (error) => {

                  console.log('Error: ', error);

            };
      }

      const onDrop = async acceptedFiles => {

            console.log(acceptedFiles);

            await getBase64(acceptedFiles);

            setFile(acceptedFiles);

      }

      const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: 0 });

      const onChange0 = event => setuser_name(event.target.value);

      const onChange1 = event => setsure_name(event.target.value);

      const onChange2 = event => setemail(event.target.value);

      const onChange7 = event => setpassword(event.target.value);

      const oncancel = () => {

            setuser_name('');

            props.cancelmodel(false);

      }

      const updateUser = useCallback(

            (event) => {

                  console.log(file);
                  setspin(1);

                  if (user_name !== '' && email !== '' && password !== '') {

                        uploadFile({

                              variables: {

                                    _id: props.data._id,

                                    user_name: user_name,

                                    sure_name: sure_name,

                                    email: email,

                                    user_type: user_type,

                                    file: file,

                                    password: password,

                                    lang: ens,

                              },

                        }).then(result => {

                              setspin(0);

                              console.log(result.data);

                              props.updateuser(result.data);

                        });

                  } else {

                        setspin(0);

                        Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

                  }
            },
            [uploadFile]
      );

      strings.setLanguage(ens);

      return (

            <div className="gutter-example">

                  <Spin tip="Loading..." className={spin === 0 ? 'd-none' : ''}  > </Spin>

                  <Row gutter={16}>

                        <Col className="gutter-row" span={18}>

                              <Row gutter={16}>

                                    <Col className="gutter-row" span={16}>

                                          <div className='d-flex' style={{ marginBottom: 16 }}>

                                                <Badge status="processing" text="" />

                                                <Input placeholder={strings.an} value={user_name} onChange={onChange0} />

                                          </div>

                                    </Col>

                                    <Col className="gutter-row" span={8}>

                                          <div style={{ marginBottom: 16 }}>

                                                <Input placeholder={strings.sn} value={sure_name} onChange={onChange1} />

                                          </div>

                                    </Col>

                              </Row>

                              <Row gutter={16}>

                                    <Col className="gutter-row" span={24}>

                                          <div className='d-flex' style={{ marginBottom: 16 }}>

                                                <Badge status="processing" text="" />

                                                <Input placeholder={strings.email} value={email} onChange={onChange2} />

                                          </div>

                                    </Col>

                              </Row>

                        </Col>

                        <Col className="gutter-row" span={6}>

                              <div style={{ marginBottom: 16 }}>

                                    <div className="gutter-box">

                                          <div {...getRootProps()} className={imageUrl === URI + '/images/undefined' || imageUrl === '' ? "comanfile_upload d-flex" : "d-none"} >

                                                <input {...getInputProps()} />

                                                {isDragActive ? (<p>Drop the files here ...</p>) : (<Icon type="cloud-upload" className="m-auto icon" />)}

                                          </div>

                                          <img src={imageUrl} className={imageUrl === URI + '/images/undefined' || imageUrl === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '4.5em' }} />

                                          <Icon type="delete" style={{ marginLeft: '2.5em' }} theme="twoTone" className={imageUrl === URI + '/images/undefined' || imageUrl === '' ? "d-none" : ""} onClick={() => { setimageUrl(''); setFile(''); }} />

                                    </div>

                              </div>

                        </Col>

                  </Row>

              

                  <Row gutter={16}>

                        <Col className="gutter-row" span={24}>

                              <div className='d-flex' style={{ marginBottom: 16 }}>

                                    <Badge status="processing" text="" />

                                    <Input.Password placeholder={strings.pw} value={password} onChange={onChange7} />

                              </div>

                        </Col>

                  </Row>

                

                  <Row>

                        <Col>

                              <Button key="back" onClick={oncancel}> Cancel </Button>

                              <Button className="float-right" key="submit" type="primary" onClick={updateUser}> Submit </Button>

                        </Col>

                  </Row>

            </div>
      );
};

