import { Layout, AutoComplete, Icon,Switch, Badge, Avatar, LocaleProvider, DatePicker, Spin, Descriptions, Input, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select, Tag } from 'antd';
import React, { useCallback, useEffect } from "react";
import { Query } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";
import { Alert } from '../../Layout/Files';
import essity_logo from '../../../intro.svg';
import { URI, client } from '../../../graphql/Client';
import { GET_REPORT, DELETE_REPORT, DOWNLOAD_REPORT, SEARCH_REP } from '../../../graphql/Report';
import { USER_EMAIL_QUERY } from '../../../graphql/Report';
import { SEARCH_USER } from '../../../graphql/User';
import { ADD_PENALTY } from '../../../graphql/Penalty';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
const { Title, Text } = Typography;
export const Add_Penalty_Manage = (props) => {
    const [emails, setemails] = React.useState('');
    const [dataSource, setdataSource] = React.useState([]);
    const [reportdata, setreportdata] = React.useState({});
    const[show,setshow]= React.useState(0);
    const[switchs,setswitchs]=React.useState(0);
    const handleSearch = async value => {
        setemails(value);
        console.log(value);
        if (value.length >= 1) {
            const { data } = await client.query({
                query: USER_EMAIL_QUERY,
                variables: { data: { email: value }, lang: ens },
                fetchPolicy: 'no-cache',
            });
            setdataSource(data ? data.search_penalty_email : [])
           
        }
    };

    const onSelect = async value => {
        console.log(value);
        setemails('');
        client.query({
            query: SEARCH_USER,
            variables: {data: { _id: value }, lang: ens },
            fetchPolicy: 'no-cache',
      }).then(result => {
            console.log(result.data.search_user[0]);
            if(result.data.search_user.lenght === 0){
                setshow(0)
                Alert({msg:" No user present in DB!",status:"failed"});
            }else{
                setreportdata(result.data.search_user[0])
                setshow(1)
            }
            
      });
    }

    const addPenalty = async (checked, e) => {
        if (checked == 1) {
            console.log(reportdata);
              await client.mutate({
                    mutation: ADD_PENALTY,
                    variables: { penality_userID: reportdata._id, lang: ens },
              }).then(result => {
                    if (result.data.addPenalty.status === 'success') {
                          setswitchs(1);
                    }
                    Alert(result.data.addPenalty);
              });
        }
  }



    return (
        <div>
            <Row>
                <Col span={24}>
                <Title level={3}>Add Penalty</Title>
                </Col>
                <Col span={24}>
                <Title level={4}>
                <Text type="warning">Search the email to add penalty if email is present in our record its display after Select view the detail.  </Text>
                </Title>
                </Col>
            </Row>
            <Row>
                <AutoComplete
                    onSelect={onSelect}
                    onSearch={handleSearch}
                    placeholder="Enter Email Name"
                    value={emails}
                >
                    {dataSource.map((user, index) => {
                        return (
                            <AutoComplete.Option key={user._id}>
                                {user.email}
                            </AutoComplete.Option>
                        );
                    })}
                </AutoComplete>
            </Row>

            <Descriptions className={show === 1 ? "py-5" : "d-none"} title={strings.pu} layout="vertical" bordered>
                <Descriptions.Item label={strings.un}> {reportdata.user_name} </Descriptions.Item>
                <Descriptions.Item label={strings.email} >{reportdata.email}</Descriptions.Item>
                <Descriptions.Item label={strings.ui}>
                    <img src={essity_logo} alt={'GEPP'} style={{ height: '2em' }} />
                </Descriptions.Item>
                <Descriptions.Item label={strings.vd}>
                    <Row> <Col span={12}> {strings.vlp} : </Col> <Col span={12}> <Text code> {reportdata.vehicle_licenseplate}</Text> </Col> </Row>
                    <Row> <Col span={12}> {strings.vm} : </Col> <Col span={12}> <Text code> {reportdata.vehicle_model}</Text> </Col> </Row>
                    <Row> <Col span={12}> {strings.vc} : </Col> <Col span={12}> <Text code> {reportdata.vehicle_color}</Text> </Col> </Row>
                </Descriptions.Item>
                <Descriptions.Item label={strings.ut} >{reportdata.user_type}</Descriptions.Item>
                <Descriptions.Item label={strings.st} >
                    <Row>
                        <Col span={12}>  <Badge status="processing" text={strings.ps} /> : </Col>
                        <Col span={12}>  <Switch checkedChildren="Penality D-Active" disabled={switchs === 1 ? 1 : 0} unCheckedChildren="Penality Active" onChange={(b, e) => {addPenalty(b, e) }} /> </Col>
                    </Row>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};
