import gql from "graphql-tag";
export const GET_REPORT = gql`
      query GETREPORT($_id: ID) {
            report(_id:$_id)
            {    
                  _id
                  report_userID,
                  booking_status,
                  slot_id,
                  role_id,
                  photo,
                  report_date,
                  reservation,
                  date_formate,
                  url,
                  status,
                  msg,
                  admin_report{
                        email,
                        user_name
                  },
            }
      }
`;
export const DELETE_REPORT = gql`
      mutation DELETEREPORT($_id: ID,$lang:String)  {
            deleteReport(_id:$_id,lang:$lang){
                  msg,
                  status     
            }
      }
`;
export const FIND_VECHILE = gql`
      query FINDVECHILE($vehicle_licenseplate: String) {
            find_vechile(vehicle_licenseplate:$vehicle_licenseplate)
            {    
                  _id,
                  user_name,
                  sure_name,
                  photo,
                  email,
                  vehicle_model,
                  vehicle_color,
                  vehicle_licenseplate,
                  user_type,
                  file,
                  url,
                  msg,
                  status,
            }
      }
`;
export const DOWNLOAD_REPORT = gql`
  query DOWNLOADREPORT($_id: ID) {
    downloadreports(_id:$_id){ 
      _id
    }
  }
`;
export const COUNT = gql`
{
      acceptedMessage
      {
            _id
           count
            status,
      }
}
`;
export const SEARCH_REP = gql`
      query GETREPORTS($lang: String, $data: String) {
            search_rep(lang: $lang, data: $data) 
            {    
                  _id
                  report_userID,
                  booking_status,
                  slot_id,
                  photo,
                  report_category,
                  report_date,
                  date_formate,
                  reservation,
                  url,
                  status,
                  msg
            }
      }
`;
export const GETVN = gql`
      query GETVNS($lang: String, $data: String) {
            search_vn(lang: $lang, data: $data) 
            {    
                 vehicle_licenseplate
            }
      }
`;


export const USER_EMAIL_QUERY = gql`
      query findusers($data: JSON,$lang:String) {
            search_penalty_email(data: $data,lang:$lang) {
                  _id,
                  user_name,
                  sure_name,
                  photo,
                  email,
                  vehicle_model,
                  vehicle_color,
                  vehicle_licenseplate,
                  user_type,
                  file,
                  url,
                  msg,
                  slot_id,
                  password,

            }
      }
`;
