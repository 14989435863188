import gql from "graphql-tag";

export const GET_ADMIN_LOGIN = gql`

      query GETADMIN($email: String,$password:String,$lang:String) {

            admin(email:$email,password:$password,lang:$lang){ 

                  msg,
                  _id,
                  user_name,
                  status

            }

      }

`;