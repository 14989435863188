import gql from "graphql-tag";
export const GET_PENALTY = gql`
  query GETPENALTY($_id: ID,$lang:String) {
    penality(_id:$_id,lang:$lang){ 
      _id
      penality_level,
      penality_userID,
      startdate,
      enddate,
      startdate_formate,
      enddate_formate,
      pension_status,
      slot_name,
      counts,
    }
  }
`;
export const ADD_PENALTY = gql`
    mutation ADDPENALTY( $report_ID:ID, $penality_userID:ID,$lang:String) {   
      addPenalty( report_ID:$report_ID, penality_userID:$penality_userID,lang:$lang )
      {
          msg,
          status,
      }
    }
`;
export const DELETE_PENALTY = gql`
    mutation DELETEPENALTY($_id: ID,$lang:String)  {
      deletePenalty(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;
export const DELETE1_PENALTY = gql`
    mutation DELETEPENALTY($_id: ID,$lang:String)  {
      delete1Penalty(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;
export const DOWNLOAD_PENALTY = gql`
  query DOWNLOADPENALTY($_id: ID) {
    downloadpenality(_id:$_id){ 
      _id
    }
  }
`;
export const DOWNLOAD_BOOKING = gql`
  query DOWNLOADBOOKING($_id: ID) {
    downloadbooking(_id:$_id){ 
      msg,
      status
    }
  }
`;
