
import React, { useCallback } from "react";
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button, Popover, Typography, Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/login.scss';
import { Alert } from '../../Layout/Files';
import essity_logo from '../../../intro.svg';
import { GET_ADMIN } from '../../../graphql/admin';
import { client } from "../../../graphql/Client";
import { ens } from "../../Layout/TemplateHeader";

const { Header, Content } = Layout;
const { Text } = Typography;

class Login extends React.Component {

      constructor(props) {

            super(props);

            this.state = {
                  _id: '',
                  reportVisible: false,
                  user_model_visible: false,
                  dataSource: [],
                  email:'',
                  password:'',

            };

      }


      // fecth admin
      fetch_admin = () => {

            client.query({

                  query: GET_ADMIN,

                  variables: { email: this.state.email, password: this.state.password,lang:ens},

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data);

                  Alert(result.data.admin);

                  if (result.data.admin.status == "success") {

                        this.props.history.push({ pathname: '/admin-dashboard' });

                  }

            });
      }

      render() {

            const content = "if alread register your are login are else you go say admin";

            return (
                  <Layout style={{ height: '100vh' }}>

                        <Layout>

                              <Header className="bg-gradient-logo" style={{ height: '9em' }}>

                                    <Row>

                                          <Col xs={4} sm={4} md={12} lg={12} xl={12}>

                                                <div className="logo_login">
                                                      <img src={essity_logo} alt={'GEPP'} style={{ width: '24em', marginLeft: '2em' }} />
                                                </div>

                                          </Col>

                                          <Col xs={18} sm={12} md={12} lg={12} xl={12}>


                                          </Col>
                                    </Row>

                              </Header>

                              <Content className="login_dash_content d-flex App-header">

                                    <Card title="Aviso de Privacidad" className='container login_card'>

                                          <Row>

                                                <Col span={24}>
                                                <p>En su conjunto, “Essity México” reconoce la importancia que tiene el tratamiento legítimo, controlado e informado de sus datos personales, razón por la cual, de conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, y teniendo como finalidad garantizar la privacidad de cualquier persona que haya decidido compartir con nosotros sus datos personales, Essity México pone a su disposición el presente Aviso de Privacidad.</p>
<p>Si usted comparte información con Essity México, se entenderá que usted ha revisado, leído y aceptado los términos de este Aviso de Privacidad. Los Datos Personales que sean proporcionados a Essity México por usted, con motivo de los servicios prestados, o por prestarse, por esta empresa sus tenedoras, subsidiarias y/o afiliadas, serán utilizados para, entre otros fines: identificación, operación, administración y comercialización, pudiéndose transferir a terceras personas con las que, debido a la prestación de servicios, sea necesario su conocimiento. En caso de que Essity México transfiera sus datos personales a un tercero que requiera conocer los mismos, Essity México requerirá a los mismos cumplir con el presente Aviso de Privacidad. Al ser titular de datos personales, usted o su representante legal pueden ejercitar los derechos de Acceso, Rectificación, Cancelación u Oposición (“Derechos ARCO”). La solicitud por medio de la cual pretenda ejercitar los Derechos ARCO deberá ser enviada al Encargado de tratamiento de datos personales de ESSITY a través de la dirección de correo electrónico:  o nuestro domicilio en Javier Barros Sierra 555 5to piso, Col. Santa Fe, C.P. 01210, México, D.F.</p>
<p>ESSITY México se reserva el derecho a modificar los términos del presente Aviso de Privacidad, mediante su publicación en nuestro sitio web: http://www.essity.mx.</p>
<p>Le invitamos a revisar el presente Aviso de Privacidad cada vez que haga uso de nuestra plataforma, a efecto de conocer cualquier modificación que se hubiere realizado al mismo.</p>
<p>Actualizado el 01 de octubre de 2019.</p>

                                                </Col>

                                          </Row>
                                    </Card>

                              </Content>

                        </Layout>

                  </Layout>
            );
      }
}


export default withRouter(Login);
