
import React, { useCallback } from "react";

import { Layout, Icon, Badge, LocaleProvider, Avatar, Form, Input, Tooltip, Popconfirm, Button, Modal, message, DatePicker, Typography, Row, Col, Card, Table, Calendar } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/holiday.scss';
import { Alert } from '../../Layout/Files';
import TemplateSider from '../../Layout/TemplateSider';
import {TemplateHeader , ens} from '../../Layout/TemplateHeader';
import messages_sp from "../../../translate/span.json";
import messages_en from "../../../translate/en.json";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import moment from "moment";
import fr_FR from 'antd/es/locale-provider/en_US';
import 'moment/locale/es-us';
import { GET_HOLIDAY, ADD_HOLIDAY, DELETE_HOLIDAY, UPDATE_HOLIDAY } from "../../../graphql/Holiday";
import { client } from "../../../graphql/Client";
import { strings } from '../../Layout/Strings';
moment.locale('es');

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { Content } = Layout;
const { Text } = Typography;


class Holiday extends React.Component {

      constructor(props) {

            super(props);

            this.handleDayClick = this.handleDayClick.bind(this);

            this.state = {

                  en:1,

                  selectedDays: [],

                  Dayss: [],

                  dataSource: [],

                  date: '',

                  _id: '',

                  description: '',

                  showadd: 0,

                  loading: 1,


            };
      }

      componentDidMount() {

            this.fetch_holiday();

      }

      // fecth holiday
      fetch_holiday = () => {

            client.query({

                  query: GET_HOLIDAY,

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data.holiday);

                  this.setState({ loading: false, dataSource: result.data.holiday, showupdate: 0, showadd: 0 });

            });

      }

      handleDayClick = async (value) => { console.log(); this.setState({ date: moment(value).format('MM-DD') }) }

      saved = async () => {

            const { date, description } = this.state;

            console.log();

            if (date !== '' && description !== '') {

                  await client.mutate({

                        mutation: ADD_HOLIDAY,

                        variables: { holiday: date, description, lang: ens},

                  }).then(result => {

                        Alert(result.data.addHoliday);

                        this.fetch_holiday();

                  });


            } else {

                  Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

            }


      }

      deleteHoliday = async (_id) => {

            console.log(_id);

            await client.mutate({

                  mutation: DELETE_HOLIDAY,

                  variables: { _id: _id, lang: ens },

            }).then(result => {

                  Alert(result.data.deleteHoliday);
                  this.fetch_holiday();

            });

      }

      findHoliday = async (_id) => {

            console.log(_id);

            await client.query({

                  query: GET_HOLIDAY,

                  variables: { _id: _id },

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data.holiday[0].holiday);

                  this.setState({ _id: result.data.holiday[0]._id, date: result.data.holiday[0].holiday, description: result.data.holiday[0].description, showupdate: 1 });

            });
      }

      editHoliday = async () => {

            const { date, description, _id } = this.state;

            if (description !== '') {

                  await client.mutate({

                        mutation: UPDATE_HOLIDAY,

                        variables: { _id, holiday: date, description, lang: ens },

                  }).then(result => {

                        Alert(result.data.updateHoliday);
                        this.fetch_holiday();

                  });

            } else {

                  Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

            }
      }




      render() {

            const columns = [
                  {
                        title: <span>{strings.date}</span>,
                        dataIndex: '',
                        key: 'holiday',
                        width: 20,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="DATE">{record.holiday}</span>;
                        }
                  },
                  {
                        title: <span>{strings.ds}</span>,
                        dataIndex: '',
                        key: 'description',
                        width: 25,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Description">{record.description}</span>;
                        }
                  },

                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>
                                                <Icon type="edit" tabIndex="-1" onClick={() => this.findHoliday(record._id)} />
                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteHoliday(record._id)}>
                                                      <Icon type="delete" />
                                                </Popconfirm>
                                          </div>
                                    );
                              }
                        }
                  },
            ];

            return (
                  <div>
                        <Modal
                              title={strings.ah}
                              style={{ top: 20 }}
                              visible={this.state.showadd}
                              onCancel={() => this.setState({ showadd: 0 })}
                              footer={[]} >

                              <Row gutter={16} className="pb-5">

                                    <Col span={24}>

                                          <div className='d-flex'>

                                                <Badge status="processing" text="" />

                                                <Input placeholder={strings.ds} value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />

                                          </div>

                                    </Col>

                              </Row>

                              <Row gutter={16}>

                                    <Col span={16}>

                                          <div className='d-flex'>

                                                <Badge status="processing" text="" />

                                                <LocaleProvider locale={fr_FR}>
                                                <DatePicker style={{ width: '-webkit-fill-available' }} placeholder="Select Month" format="MM-DD" onChange={this.handleDayClick} />
                                                </LocaleProvider>
                                          </div>
                                          
                                    </Col>

                                    <Col span={8}>

                                          <Button onClick={this.saved} style={{ width: '-webkit-fill-available' }} type="primary" >add</Button>

                                    </Col>

                              </Row>

                        </Modal>

                        <Modal
                              title={strings.uh}
                              style={{ top: 20 }}
                              visible={this.state.showupdate}
                              onCancel={() => this.setState({ showupdate: 0 })}
                              footer={[]} >

                              <Row gutter={16} className="pb-5">

                                    <Col span={24}>

                                          <div className='d-flex'>

                                                <Badge status="processing" text="" />

                                                <Input placeholder={strings.ds} value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />

                                          </div>

                                    </Col>

                              </Row>

                              <Row gutter={16}>

                                    <Col span={16}>

                                          <div className='d-flex'>

                                                <Badge status="processing" text="" />

                                                <DatePicker style={{ width: '-webkit-fill-available' }} placeholder={this.state.date} format="MM-DD" onChange={this.handleDayClick} />

                                          </div>

                                    </Col>

                                    <Col span={8}>

                                          <Button onClick={this.editHoliday} style={{ width: '-webkit-fill-available' }} type="primary" >UPDATE</Button>

                                    </Col>

                              </Row>

                        </Modal>

                        <div className="user_add_position">

                              <Tooltip placement="left" title={strings.ah}>

                                    <Icon type="carry-out" className="user_add icon_size" onClick={() => { this.setState({ showadd: 1 }) }} />

                              </Tooltip>

                        </div>

                        <Row gutter={16}>

                              <Col span={24}>

                                    <div id="no-more-tables">

                                          <Table
                                                pagination={{ simple: true }}
                                                dataSource={this.state.dataSource}
                                                columns={columns}
                                                bordered
                                                loading={this.state.loading}
                                                onChange={this.onTableChange}
                                          />

                                    </div>

                              </Col>

                        </Row>

                  </div>
            );
      }
}

export default Holiday;