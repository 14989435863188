import { message, Button } from 'antd';

export const Alert = (data) => {

  console.log(data);

  if (data.status === 'success') {

    return (message.success(data.msg, [5]));

  } else {

    return (message.error(data.msg,[5]));
    
  }

};