import React, { useCallback } from "react";
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { Layout, Icon, Avatar, Switch, AutoComplete, Descriptions, Badge, Form, Input, Tooltip, Button, message, Popconfirm, Typography, Row, Col, Card, Table, Modal } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import { Alert } from '../../Layout/Files';
import essity_logo from '../../../intro.svg';
import { client } from "../../../graphql/Client";
import { GET_REPORT, FIND_VECHILE, GETVN } from '../../../graphql/Report';
import { USER_EMAIL_QUERY } from '../../../graphql/User';
import { ADD_PENALTY } from "../../../graphql/Penalty";
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import { from } from "zen-observable";
const { Content } = Layout;
const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = AutoComplete;
class Report_View extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  show: 0,
                  dataSource: [],
                  disabled: true,
                  _ID: '',
                  msg: '',
                  photo: '',
                  description: '',
                  report_date: '',
                  report_userID: {},
                  status: '',
                  reportVisible: '',
                  reported_user: {},
                  booking_status: {},
                  slot: {},
                  slotstatus: '',
                  user_url: '',
                  dataSources: [],
                  values: '',
                  switchs: 0,
                  vlp1: '',
            };
      }
      componentWillMount() {
            this.fetch_User(this.props.location.state._id);
            // this.onSearch();
      }
      // fecth user
      fetch_User = (_id) => {
            client.query({
                  query: GET_REPORT,
                  variables: { _id: _id, lang: ens },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.trace(result.data.report[0]);
                  if (result.data.report[0].slot_id == null) {
                        this.setState({
                              msg: result.data.report[0].msg,
                              photo: result.data.report[0].url,
                              description: result.data.report[0].description,
                              _ID: result.data.report[0]._id,
                              report_date: result.data.report[0].date_formate,
                              report_userID: result.data.report[0].report_userID,
                              status: result.data.report[0].booking_status,
                              slot: '',
                              slotstatus: result.data.report[0].status,
                        });
                  } else {
                        this.setState({
                              msg: result.data.report[0].msg,
                              photo: result.data.report[0].url,
                              description: result.data.report[0].description,
                              _ID: result.data.report[0]._id,
                              report_date: result.data.report[0].date_formate,
                              report_userID: result.data.report[0].report_userID,
                              status: result.data.report[0].booking_status,
                              slot: result.data.report[0].slot_id,
                              slotstatus: result.data.report[0].status,
                        });
                  }
            });
      }
      // onSearch = searchText => {
      //       console.log("ss");
      //       client.query({
      //             query: GETVN,
      //             fetchPolicy: 'no-cache',
      //       }).then(result => {
      //             console.log(result.data.search_vn);
      //             this.setState({ dataSource: result.data.search_vn})
      //       });
      // };
      search = async (data) => {
            console.log(this.state.values);
            client.query({
                  query: FIND_VECHILE,
                  variables: { vehicle_licenseplate: this.state.values, lang: ens },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  if (result.data.find_vechile.length !== 0) {
                        this.setState({ reported_user: result.data.find_vechile[0], show: 1, });
                  } else {
                        Alert({ msg: "This Vechile Is Not Present In User Data !", status: "failed" });
                        this.setState({ show: 0, });
                  }
            });
      }
      handleSearch = async value => {
            this.setState({ values: value });
            console.log(value);
            if (value.length >= 1) {
                  const { data } = await client.query({
                        query: USER_EMAIL_QUERY,
                        variables: { data: { email: value }, lang: ens },
                  });
                  this.setState({ dataSource: data ? data.search_user_email : [] })
            }
      };
      addPenalty = async (checked, e) => {
            const { _ID, reported_user } = this.state;
            if (checked == 1) {
                  await client.mutate({
                        mutation: ADD_PENALTY,
                        variables: { report_ID: _ID, penality_userID: reported_user._id, lang: ens },
                  }).then(result => {
                        if (result.data.addPenalty.status === 'success') {
                              this.setState({ switchs: 1 });
                        }
                        Alert(result.data.addPenalty);
                  });
            }
      }
      onSelect = async value => {

      }

      render() {
            strings.setLanguage(ens);
            const { dataSource, report_userID, description, report_date, status, values, reported_user, slot, slotstatus, photo } = this.state;
            var datas = [];
            // dataSource.map(data => { datas.push(data.vehicle_licenseplate); });
            console.log(status);
            return (
                  <div>
                        <Row className="mb-5">
                              <Col span={24}> <Title level={3}>{strings.rd}</Title> </Col>
                              {/* <Col span={12}>
                                    <div className="float-right">

                                    <AutoComplete
                                          onSelect={(value) => { this.setState({ values: value }) }}
                                          onSearch={this.handleSearch}
                                          placeholder="Enter vehicle_licenseplate"
                                          value={values}
                                    >
                                          {dataSource.map((user, index) => {
                                                console.log(user);
                                                return (
                                                      <AutoComplete.Option key={user.vehicle_licenseplate}>
                                                            {user.vehicle_licenseplate}
                                                      </AutoComplete.Option>
                                                );
                                          })}
                                    </AutoComplete>
                                    <Button
                                          className="search-btn"
                                          style={{ marginRight: -12 }}
                                          type="primary"
                                          onClick={this.search}
                                    >
                                          <Icon type="search" />
                                    </Button>
                                    </div>


                              </Col> */}
                        </Row>
                        <Descriptions className={this.state.show === 1 ? "py-5" : "d-none"} title={strings.pu} layout="vertical" bordered>
                              <Descriptions.Item label={strings.un}> {reported_user.user_name} </Descriptions.Item>
                              <Descriptions.Item label={strings.email} >{reported_user.email}</Descriptions.Item>
                              <Descriptions.Item label={strings.ui}>
                                    <img src={essity_logo} alt={'GEPP'} style={{ height: '2em' }} />
                              </Descriptions.Item>
                              <Descriptions.Item label={strings.ud}>
                                    <Row> <Col span={12}> {strings.position} : </Col> <Col span={12}> <Text code> {reported_user.position}</Text> </Col> </Row>
                                    <Row> <Col span={12}> {strings.group} : </Col> <Col span={12}> <Text code> {reported_user.group}</Text> </Col> </Row>
                                    <Row> <Col span={12}> {strings.ut} : </Col> <Col span={12}> <Text code> {reported_user.user_type}</Text> </Col> </Row>
                              </Descriptions.Item>
                              {/* <Descriptions.Item label={strings.ut} >{reported_user.user_type}</Descriptions.Item> */}
                              <Descriptions.Item label={strings.st} >
                                    <Row>
                                          <Col span={12}>  <Badge status="processing" text={strings.ps} /> : </Col>
                                          <Col span={12}>  <Switch checkedChildren="Penality D-Active" disabled={this.state.switchs === 1 ? 1 : 0} unCheckedChildren="Penality Active" onChange={(b, e) => { this.addPenalty(b, e) }} /> </Col>
                                    </Row>
                              </Descriptions.Item>
                        </Descriptions>
                        {/* reported user */}
                        <Descriptions title="" layout="vertical" bordered>
                              <Descriptions.Item label={strings.un}> {report_userID.user_name}{report_userID.sur_name}</Descriptions.Item>
                              <Descriptions.Item label={strings.email} >{report_userID.email}</Descriptions.Item>
                              <Descriptions.Item label={strings.st} span={3}> <Badge status="processing" text={status === null ? 'finished' : status.status} /> </Descriptions.Item>
                              <Descriptions.Item label={strings.ut} >{report_userID.user_type}</Descriptions.Item>
                              <Descriptions.Item label={strings.date}>{report_date}</Descriptions.Item>
                              <Descriptions.Item label={strings.rds} span={3}>
                                    <Row>
                                          <Col span={12}>{strings.cat} </Col>
                                          <Col span={12}>
                                                : <Text code> {slot.category} </Text>
                                          </Col>
                                    </Row>
                                    <Row>
                                          <Col span={12}> {strings.SLOT} : </Col>
                                          <Col span={12}>
                                                : <Text code> {slot.slot_name}_{slot.slot_no}</Text>
                                          </Col>
                                    </Row>
                                    <Row>
                                          <Col span={12}> {strings.st} : </Col>
                                          <Col span={12}>
                                                : <Text code> {slotstatus}</Text>
                                          </Col>
                                    </Row>
                              </Descriptions.Item>
                              {/* <Descriptions.Item label={strings.vd}>
                                    <Row> <Col span={12}> VECHILE NUMBER  </Col> <Col span={12}> : <Text code> {report_userID.vehicle_licenseplate}</Text> </Col> </Row>
                                    <Row> <Col span={12}> VECHILE MODEL  </Col> <Col span={12}> : <Text code> {report_userID.vehicle_model}</Text> </Col> </Row>
                                    <Row> <Col span={12}> VECHILE COLOR  </Col> <Col span={12}> : <Text code> {report_userID.vehicle_color}</Text> </Col> </Row>
                              </Descriptions.Item> */}
                              <Descriptions.Item label={strings.ui}>
                                    <img src={report_userID.url} alt={"NO IMAGE"} style={{ height: '12em' }} />
                              </Descriptions.Item>
                              <Descriptions.Item label={strings.ri}>
                                    <img src={photo} alt={"NO IMAGE"} style={{ height: '12em' }} />
                              </Descriptions.Item>
                        </Descriptions>
                  </div>
            );
      }
}
export default withRouter(Report_View);
