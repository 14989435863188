
import React, { useCallback } from "react";
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button, message, Popconfirm, Typography, Row, Col, Card, Table, Modal, Tag, Upload } from 'antd';
import { Uploads } from './Uploads';
import { Update } from './Update';
import { Alert } from '../../Layout/Files';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/User.scss';
import TemplateSider from '../../Layout/TemplateSider';
import {ens} from '../../Layout/TemplateHeader';
import gql from "graphql-tag";
import { GET_ADMIN, FIND_ADMIN, DELETE_ADMIN, SEARCH_ADMIN } from '../../../graphql/admin';
import { client, URI } from "../../../graphql/Client";
import FileDrop from 'react-file-drop';
import { strings } from '../../Layout/Strings';

const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;

class Admin_Mange extends React.Component {

      constructor(props) {
            super(props);
            this.state = {
                  _id: '',
                  reportVisible: false,
                  user_model_visible: false,
                  pictures: [],
                  user_name: '',
                  sure_name: '',
                  email: '',
                  vehicle_color: '',
                  password: '',
                  vehicle_licenseplate: '',
                  vehicle_model: '',
                  user_type: '',
                  file: '',
                  dataSource: [],
                  data: {},
                  loading: 1,

            };
      }

      componentDidMount() {

            this.fetch_User();
      }

      // fecth user
      fetch_User = (visible) => {


            client.query({

                  query: GET_ADMIN,

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data.admins);

                  this.setState({ loading: false, user_model_visible: visible, reportVisible: visible, dataSource: result.data.admins });

            });

          

      }

      fetch_Users = () => {

            client.query({

                  query: GET_ADMIN,

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data.admins);

                  this.setState({ loading: false, dataSource: result.data.admins });

            });

      }

   

      // update user
      updateUser = async (data) => {

            console.log(data);

            Alert(data.updateAdmin);

            this.fetch_User(false);

      }

      // add user
      addUser = async (data) => {

            console.log(data.addAdmin);

            Alert(data.addAdmin);

            this.fetch_User(false);

      }

      // delete a record
      reportDelete = async (_id) => {

            console.log(_id);

            await client.mutate({

                  mutation: DELETE_ADMIN,

                  variables: { _id: _id, lang: ens},

            }).then(result => {

                  Alert(result.data.deleteAdmin);
                  this.fetch_User();

            });

      };

      // user data visible in update process

      userVisible = async (_id) => {

            console.log(_id);

            await client.query({

                  query: FIND_ADMIN,

                  variables: { _id: _id, lang: ens},

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data);

                  this.setState({ data: result.data.admin_s, reportVisible: true });

            });
      }

      onTableChange = (pagination, filters, sorter) => {
            console.log(pagination, filters, sorter);
      }

      res_search = value => {

            client.query({

                  query: SEARCH_ADMIN,

                  variables: { lang: ens, data: value },

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data);
                  if (result.data.search_admin.length != 0) {

                        this.setState({ loading: false, dataSource: result.data.search_admin });

                  } else {

                        Alert({ msg: "INVALID DATA", status: 'failed' });

                  }

            });
      }

      render() {

            strings.setLanguage(ens);

            const columns = [
                  {
                        title: <span>{strings.an}</span>,
                        dataIndex: '',
                        key: '',
                        width: 20,
                        align: 'center',
                        // defaultSortOrder: 'descend',
                        // sorter: (a, b) => a.user_name.length - b.user_name.length,
                        // sortDirections: ['descend', 'ascend'],
                        render: (text, record) => {
                              return <span title="NAME">{record.user_name}</span>;
                        }
                  },
                  {
                        title:<span>{strings.email}</span>,
                        key: 'emial',
                        width: 25,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="EMAIL">{record.email}</span>;
                        }
                  },
                  {
                        title:<span>{strings.ut}</span>,
                        dataIndex: '',
                        key: 'user_type',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="USER TYPE">{record.user_type}</span>;
                        }
                  },
                
                  {
                        title: <span>{strings.ph}</span>,
                        dataIndex: 'url',
                        key: 'url',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="USER IMAGE"> <Avatar src={record.url} /> </span>;
                        }
                  },
                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>
                                                <Icon type="edit" tabIndex="-1" onClick={() => this.userVisible(record._id)} />
                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.reportDelete(record._id)}>
                                                      <Icon type="delete" />
                                                </Popconfirm>
                                          </div>
                                    );
                              }
                        }
                  },
            ];

            return (
                  <div>
                        <Modal
                              title="View Detail Report"
                              style={{ top: 20 }}
                              visible={this.state.reportVisible}
                              onCancel={() => this.setState({ reportVisible: 0, data: '' })}
                              footer={[]} >
                              <Update updateuser={this.updateUser} data={this.state.data} cancelmodel={(data) => { this.setState({ reportVisible: data }) }} />
                        </Modal>

                        {/* add user in model */}

                        <Modal
                              title="ADD USER"
                              style={{ top: 20 }}
                              visible={this.state.user_model_visible}
                              onCancel={() => this.setState({ user_model_visible: 0, data: '' })}
                              footer={[]} >
                              <Uploads adduser={this.addUser} cancelmodel={(data) => { console.log(data); this.setState({ user_model_visible: 0, data: '' }); }} />
                        </Modal>

                        {/* add user icon button */}
                        {/* <Tooltip trigger={window.cordova ? 'click' : 'hover'} placement="left" title={"ADD USER"}> */}
                        <div data-toggle="tooltip" title="ADD USER!" data-placement="left" className="user_add_position">
                              <Icon type="user-add" className="user_add icon_size" onClick={() => this.setState({ user_model_visible: true })} />
                        </div>
                        {/* </Tooltip> */}

                        <Row>

                              <Col className='float-left d-flex' style={{ top: '-2vh' }} xl={8} sm={24}>

                                    <Search placeholder={strings.email} enterButton="Search" size="small" onSearch={(value) => { this.res_search(value) }} />

                                    <Button className="ml-4" type="dashed" size="small" onClick={this.fetch_Users} >ALL USERS</Button>

                              </Col>

                        </Row>

                        <div id="no-more-tables">
                              <Table expandedRowRender={record =>
                                    <div className="expand">
                                          <Row>
                                                <Col sm={24} lg={12}>
                                                      <Row>
                                                            <Col sm={12} lg={12}>
                                                                 <span>{strings.pw}</span>
                                                            </Col>
                                                            <Col sm={12} lg={12}>
                                                                 : <Tag color="green" >{record.password}</Tag>
                                                            </Col>
                                                      </Row>
                                                </Col>
                                          </Row>
                                    </div>}
                                    dataSource={this.state.dataSource}
                                    columns={columns}
                                    bordered
                                    loading={this.state.loading}
                                    onChange={this.onTableChange}
                                    pagination={{ simple: true }}
                              />
                        </div>
                  </div>

            );
      }
}

export default Admin_Mange;
