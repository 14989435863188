import gql from "graphql-tag";
export const GET_STATIC = gql`
{
    Static
    {
        _id,
        title,
        description,
        content,
        other,
        msg,
        status,
    }
}
`;

export const FIND_STATIC = gql`
    query findstatic($_id: ID,$lang:String) {
        Static(_id: $_id,lang:$lang) {
            _id,
            title,
            description,
            content,
            other,
            msg,
            status,
        }
    }
`;
export const ADD_STATIC = gql`
    mutation ADDSTATIC($title:String,$description:String,$content: String,$other:String,$lang:String) {   
      addStatic(title:$title, description:$description, content: $content,other:$other,lang:$lang)
        {
            _id,
            title,
            description,
            content,
            other,
            msg,
            status,
        }
    }
`;


export const UPDATE_STATIC = gql`
    mutation UPDATESTATIC($_id:ID,$title:String, $description:String, $content: String, $other:String,$lang:String) {   
        updateStatic(_id:$_id,title: $title,description: $description, content: $content,other:$other,lang:$lang)
        {
            msg
            status         
        }
}`

export const DELETE_STATIC = gql`
    mutation DELETESTATIC($_id: ID,$lang:String)  {
      deleteStatic(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;

