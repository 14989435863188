
import React, { useCallback, useEffect } from "react";

import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';

import { useDropzone } from "react-dropzone";

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import gql from "graphql-tag";

import { Layout, Icon,Badge, Avatar, Form, Input, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select } from 'antd';

import { useMutation } from "@apollo/react-hooks";

import { Alert } from '../../Layout/Files';

import { ADD_STATIC, UPDATE_STATIC } from '../../../graphql/Static';

import { ens, TemplateHeader } from '../../Layout/TemplateHeader';

import { strings } from '../../Layout/Strings';

const { TextArea } = Input;

const { Option } = Select;

export const Uploads = withRouter((props) => {

      const [uploadFile] = useMutation(ADD_STATIC, {});

      const [uploadStatic] = useMutation(UPDATE_STATIC, {});

      const [title, settitle] = React.useState('');

      const [others, setothers] = React.useState('');

      const [content, setcontent] = React.useState('');

      const [description, setdescription] = React.useState('');

      const [btns, setbtns] = React.useState(false);


      useEffect(() => {

            console.log(props);

            if (props.location.state === undefined) {

            } else {

                  settitle(props.location.state.data[0].title);

                  setothers(props.location.state.data[0].other);

                  setcontent(props.location.state.data[0].content);

                  setdescription(props.location.state.data[0].description);

                  setbtns(true);
            }
      }, [props]);

      const onTitle = event => settitle(event.target.value);

      const onOthers = event => setothers(event.target.value);

      const onDescription = event => setdescription(event.target.value);

      const contentChange = (value) => {

            setcontent(value);

      }

      const updateStatic = useCallback(

            (event) => {

                  console.log("update");

                  if (title !== '' && description !== '' && content !== '') {

                        uploadStatic({

                              variables: {

                                    _id: props.location.state.data[0]._id,

                                    title: title,

                                    description: description,

                                    content: content,

                                    others: others,

                                    lang: ens,
                              },

                        }).then(result => {

                              console.log(result.data);

                              props.history.push('./admin-static');

                        });

                  } else {

                        Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

                  }

            },
            [uploadStatic]
      );

      const addStatic = useCallback(

            (event) => {

                  if (title !== '' && description !== '' && content !== '') {

                        
                        uploadFile({

                              variables: {

                                    title: title,

                                    description: description,

                                    content: content,

                                    others: others,

                                    lang : ens,

                              },

                        }).then(result => {

                              console.log(result.data);

                              props.history.push('./admin-static');

                        });

                  } else {

                        Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

                  }
            },
            [uploadFile]

      );

      strings.setLanguage(ens);

      return (
            <div className="gutter-example">

                  <Row gutter={16}>

                        <Col className="gutter-row" sm={24} lg={18}>

                              <Row gutter={16}>

                                    <Col className="gutter-row" span={24}>

                                          <div className='d-flex' style={{ marginBottom: 16 }}>

                                                <Badge status="processing" text="" />

                                                <ReactQuill className={props.reactquill} value={content} placeholder={strings.ct}
                                                      onChange={contentChange} style={{ width: '-webkit-fill-available'}}/>

                                          </div>

                                    </Col>

                              </Row>

                        </Col>

                        <Col className="gutter-row title_top" sm={24} lg={6}>

                              <Row gutter={16}>

                                    <Col className="gutter-row" span={24}>

                                          <div className='d-flex' style={{ marginBottom: 16 }}>

                                                <Badge status="processing" text="" />

                                                <Input placeholder={strings.ti} value={title} onChange={onTitle} />

                                          </div>

                                    </Col>

                              </Row>

                              <Row gutter={16}>

                                    <Col className="gutter-row" span={24}>

                                          <div className='d-flex' style={{ marginBottom: 16 }}>

                                                <Badge status="processing" text="" />

                                                <TextArea style={{ minHeight: '39vh' }} placeholder={strings.ds} value={description} onChange={onDescription} />

                                          </div>

                                    </Col>

                              </Row>

                              <Row gutter={16}>

                                    <Col className="gutter-row" span={24}>

                                          <div style={{ marginBottom: 16 }}>

                                                <Input placeholder={strings.other} value={others} onChange={onOthers} />

                                          </div>

                                    </Col>

                              </Row>

                              <Row>

                                    <Col>

                                          <Button key="back" onClick={() => { props.history.push('./admin-static'); }}> Cancel </Button>

                                          <Button key="submit" type="primary" className={btns == false ? "float-right" : "d-none"} onClick={addStatic}> Save </Button>

                                          <Button key="submit" type="primary" className={btns == false ? "d-none" : "float-right"} onClick={updateStatic}> Update </Button>

                                    </Col>

                              </Row>

                        </Col>

                  </Row>




            </div>
      );
});

