
import React, { useCallback } from "react";
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button, Popover, Typography, Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/login.scss';
import { Alert } from '../../Layout/Files';
import gepp_logo from "../../../gepp.png";
import { GET_ADMIN_LOGIN } from '../../../graphql/Login';
import { client } from "../../../graphql/Client";
import { ens } from "../../Layout/TemplateHeader";

const { Header, Content } = Layout;
const { Text } = Typography;

class Login extends React.Component {

      constructor(props) {

            super(props);

            this.state = {
                  _id: '',
                  reportVisible: false,
                  user_model_visible: false,
                  dataSource: [],
                  email:'',
                  password:'',

            };

      }

      componentWillMount(){

            localStorage.getItem('status') === "success" ? this.props.history.push({ pathname: '/admin-dashboard' }) : this.props.history.push({ pathname: '/' }); 
      }

      // fecth admin
      fetch_admin = () => {

            client.query({

                  query: GET_ADMIN_LOGIN,

                  variables: { email: this.state.email, password: this.state.password,lang:ens},

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data);

                  Alert(result.data.admin);

                  if (result.data.admin.status == "success") {

                        localStorage.setItem("status", "success");
                        localStorage.setItem("_id", result.data.admin._id);

                        this.props.history.push({ pathname: '/admin-dashboard' });

                  }

            });
      }

      render() {

            const content = "if alread register your are login are else you go say admin";

            return (
                  <Layout style={{ height: '100vh' }}>

                        <Layout>

                              <Header className="bg-gradient-logo" style={{ height: '12em' }}>

                                    <Row>

                                          <Col xs={4} sm={4} md={12} lg={12} xl={12}>

                                                <div className="logo_login">
                                                      <img src={gepp_logo} alt={'GEPP'}  width="170" height="95" style={{ marginLeft: '2em' }} />
                                                </div>

                                          </Col>

                                          <Col xs={18} sm={12} md={12} lg={12} xl={12}>


                                          </Col>
                                    </Row>

                              </Header>

                              <Content className="login_dash_content d-flex App-header">

                                    <Card title="Admin Login" className=' login_card mx-auto' extra={<Popover placement="right" content={content} title="ADMIN"> <Icon type="user" /> </Popover>} style={{ width: 400,height:'fit-content' }}>

                                          <Row>

                                                <Col span={24}>

                                                      <Input placeholder="ADMIN EMAIL" onChange={(e)=>{this.setState({email:e.target.value})}} />

                                                </Col>

                                          </Row>

                                          <Row className="my-5">

                                                <Col span={24}>

                                                      <Input.Password placeholder="PASSWORD" onChange={(e)=>{this.setState({password:e.target.value})}} onPressEnter={this.fetch_admin} />

                                                </Col>

                                          </Row>

                                          <Row className="">

                                                <Col span={24}>

                                                      <Button type="primary" block onClick={this.fetch_admin}>LOGIN</Button>


                                                </Col>

                                          </Row>

                                    </Card>

                              </Content>

                        </Layout>

                  </Layout>
            );
      }
}


export default withRouter(Login);
