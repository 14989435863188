import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Icon, Avatar, Form, Input, Typography, Row, Col, Menu, Dropdown, message, Badge,notification } from 'antd';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import {strings} from './Strings';
import { Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import { client } from '../../apollo';
import '../../scss/template.scss';
import gepp_logo from "../../gepp.png";
import gepp_icon from '../../gepp-icon.png';
import { Drawer, Button } from 'antd';
import { string } from 'prop-types';
const { Header } = Layout;
const { Title } = Typography;
export var ens = "en";
const ANYTHING_SUBSCRIPTION = gql`
    subscription{
      acceptedMessage{
        count
        user_name
        slot
        reservation
        date
      }
    }
`;
const DontReadTheComments = () => (
  <Subscription subscription={ANYTHING_SUBSCRIPTION}>
    {({ data: { acceptedMessage }, loading }) => (
      <div>
        <h4>New comment: {!loading && acceptedMessage[0].count}</h4>
      </div>
    )}
  </Subscription>
);
const openNotificationWithIcon = (type, name, slot, date, reservation) => {
  notification[type]({
    message: 'REPORT',
    duration: 0,
    description:
      <div>
        <Row> <Col span={12}> USER NAME </Col> <Col span={12}> {name} </Col> </Row>
        <Row> <Col span={12}> SLOT </Col> <Col span={12}> {slot} </Col> </Row>
        <Row> <Col span={12}> DATE </Col> <Col span={12}> {date} </Col> </Row>
        <Row> <Col span={12}> RESERVATION </Col> <Col span={12}> {reservation} </Col> </Row>
      </div>
    ,
  });
};
class TemplateHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      change: 1,
    }
  }
  componentWillMount() {
    DontReadTheComments();
  }
  toggle = () => {
    console.log(this.props);
    this.props.toogle(this.state.collapsed);
    if (this.state.collapsed === true) {
      this.setState({
        collapsed: false
      })
    }
    else {
      this.setState({
        collapsed: true
      })
    }
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  handleMenuClick = (e) => {
    if (e.key == '/') {
      message.info('Logout Success');
      localStorage.removeItem('status');
      this.props.history.push({ pathname: e.key });
    }
  }
  render() {
    strings.setLanguage(ens);
    const menu = (
      <Menu >
        {/* <Menu.Item key="1">PROFILE</Menu.Item>
        <Menu.Item key="2">MAIL</Menu.Item> */}
        <Menu.Item key="/" onClick={this.handleMenuClick}>
          <Icon type="user" />
            <span>{strings.LOGOUT}</span>
        </Menu.Item>
        <Button.Group >
          <Button type="primary" onClick={() => { ens = 'en'; this.props.history.push({ pathname: this.props.location.pathname }); }} >
            <Icon type="left" />
            ENGLISH
          </Button>
          <Button type="primary" onClick={() => { ens = 'es'; this.props.history.push({ pathname: this.props.location.pathname }); }} >
            SPANISH
            <Icon type="right" />
          </Button>
        </Button.Group>
      </Menu>
    );
    return (
      <div>
        <Drawer title={<img src={gepp_logo} alt={'GEPP'} width="150" height="75" />} bodyStyle={{margin:"0 0 0 14% !important"}} placement="left" closable={false} onClose={this.onClose} visible={this.state.visible} >
          <Menu theme="dark" selectedKeys={[this.props.location.pathname]} mode="inline">
            <Menu.Item key="/admin-dashboard">
              <Icon type="dashboard" />
              <span>{strings.DASHBOARD}</span>
              <Link to="/admin-dashboard" />
            </Menu.Item>
            <Menu.Item key="/admin-user">
              <Icon type="user" />
              <span>{strings.USER}</span>
              <Link to="/admin-user" />
            </Menu.Item>
            <Menu.Item key="/admin-slot">
              <Icon type="car" />
              <span>{strings.SLOT}</span>
              <Link to="/admin-slot" />
            </Menu.Item>
            <Menu.Item key="/admin-booking">
              <Icon type="book" />
              <span>{strings.BOOKING}</span>
              <Link to="/admin-booking" />
            </Menu.Item>
            <Menu.Item key="/admin-report">
              <Icon type="bell" />
              <span>{strings.REPORT}</span>
              <Subscription subscription={ANYTHING_SUBSCRIPTION}>
                {({ data, loading }) => {
                  console.log(data);
                  if (data !== undefined) {
                    openNotificationWithIcon('info', data.acceptedMessage[0].user_name, data.acceptedMessage[0].slot, data.acceptedMessage[0].date, data.acceptedMessage[0].reservation);
                    return <Badge className="ml-4" count={!loading && data.acceptedMessage[0].count} />;
                  } else {
                    return <span></span>
                  }
                }}
              </Subscription>
              <Link to="/admin-report" />
            </Menu.Item>
            {/* <Menu.Item key="/admin-penality">
              <Icon type="alert" />
              <span>{strings.PENALTY}</span>
              <Link to="/admin-penality" />
            </Menu.Item> */}
            <Menu.Item key="/admin-holiday">
              <Icon type="calendar" />
              <span>{strings.HOLIDAY}</span>
              <Link to="/admin-holiday" />
            </Menu.Item>
            <Menu.Item key="/admin-static">
              <Icon type="highlight" />
              <span>{strings.STATIC}</span>
              <Link to="/admin-static" />
            </Menu.Item>
            <Menu.Item key="/admin-archive">
            <Icon type="rest" />
            <span>{strings.ARCHIVE}</span>
            <Link to="/admin-archive" />
          </Menu.Item>
            <Menu.Item key="/admin-admin">
              <Icon type="user" />
              <span>{strings.ADMIN}</span>
              <Link to="/admin-admin" />
            </Menu.Item>
          </Menu>
        </Drawer>
        <Header className="bg-gradient-primary ">
          <Row>
            <Col xs={14} sm={14} md={14} lg={12} xl={12}>
              <Icon type="menu-fold" className=" d-lg-none d-xl-none d-xxl-none sidebar_icon" onClick={this.showDrawer} />
              <div className="float-right  float-lg-left page_title d-flex px-5">
                {/* <span>{strings.details}</span> */}
              </div>
            </Col>
            <Col xs={10} sm={10} md={10} lg={12} xl={12}>
              <div className="float-right d-flex p-3">
                <Dropdown overlay={menu}>
                  <Avatar size="medium" src={gepp_icon} alt="Admin" />
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Header>
      </div>
    );
  }
}
export default withRouter(TemplateHeader);
