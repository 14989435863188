import gql from "graphql-tag";

export const GET_SLOT = gql`

    query GETSLOT($_id: ID) {

      getSlot(_id: $_id) 
      {

            slot

            user

            msg

            status


      }

    }

`;

export const GET_USER = gql`

    query GETUSER($_id: ID) {

      getUser(_id: $_id) 
      {

           data
            
            msg

            status


      }

    }

`;

export const GET_REPORT = gql`

  query GETREPORT($_id: ID) {

    getReport(_id: $_id) 
    {

      data
      
      msg

      status

    }

  }

`;

export const GET_DASH = gql`

  query GETDASH($_id: ID) {

    getDash(_id: $_id) 
    {

      data
      
      msg

      status

    }

  }

`;


export const GET_VP = gql`

  query GETVP($_id: ID,$data:String) {

    getVP(_id: $_id,data:$data) 
    {

      data
      
      msg

      status

    }

  }

`;

export const GET_DIR = gql`

  query GETDIR($_id: ID,$data:String) {

    getDIR(_id: $_id,data:$data) 
    {

      data
      
      msg

      status

    }

  }

`;
