

import React, { useCallback } from "react";

import { Layout, Icon, Badge, Avatar, Form, Input, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select } from 'antd';

import { useMutation } from "@apollo/react-hooks";

import { Alert } from '../../Layout/Files';

import { ADD_PENALTYDETAIL } from '../../../graphql/PenaltyDetail';

import { ens, TemplateHeader } from '../../Layout/TemplateHeader';

import { strings } from "../../Layout/Strings";


const { TextArea } = Input;

const { Option } = Select;

export const Uploads = (props) => {

    const [penality_level, setlevel] = React.useState('');

    const [duration, setduration] = React.useState('');

    const [cause, setcause] = React.useState('');

    const [uploadFile] = useMutation(ADD_PENALTYDETAIL, {});

    const onChange = event => setlevel(Number(event.target.value));

    const onChange1 = event => setduration(Number(event.target.value));

    const onChange2 = event => setcause(event.target.value);

    const oncancel = () => { props.cancelmodel(false); }

    const AddPenalty = useCallback(

        (event) => {

            if (penality_level !== '' && duration !== '' && cause !== '') {

                uploadFile({

                    variables: { penality_level, duration, cause, lang: ens }

                }).then(result => {

                    console.log(result.data);

                    props.addpenalitydata(result.data);

                });

            } else {

                Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

            }

        },
        [uploadFile]
    );

    strings.setLanguage(ens);

    return (
        <div className="gutter-example">
          
            <Row gutter={16}>
             
                <Col className="gutter-row" span={24}>
                
                    <Row gutter={16}>
                     
                        <Col className="gutter-row" span={12}>

                            <div className='d-flex' style={{ marginBottom: 16 }}>

                                <Badge status="processing" text="" />

                                <Input placeholder={strings.pl} value={penality_level} onChange={onChange} />

                            </div>

                        </Col>

                        <Col className="gutter-row" span={12}>

                            <div className='d-flex' style={{ marginBottom: 16 }}>

                                <Badge status="processing" text="" />

                                <Input placeholder={strings.du} value={duration} onChange={onChange1} />

                            </div>

                        </Col>

                    </Row>
                    
                    <Row gutter={16}>

                        <Col className="gutter-row" span={24}>

                            <div className='d-flex' style={{ marginBottom: 16 }}>

                                <Badge status="processing" text="" />

                                <TextArea placeholder={strings.ds} value={cause} onChange={onChange2} rows={6} />

                            </div>

                        </Col>

                    </Row>

                    <Row>

                        <Col>

                            <Button key="back" onClick={oncancel}> Cancel </Button>

                            <Button key="submit" type="primary" onClick={AddPenalty}> Submit </Button>

                        </Col>

                    </Row>

                </Col>

            </Row>

        </div>

    );
};
